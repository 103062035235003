import store from '@/store/store';

export default {
  async getTemplatesByApplicationId(applicationId) {
    let query = `query { listTemplatesByApplicationId(ApplicationId: "${applicationId}") {
            Id
            ApplicationId
            VersionId
            Name
            Subject
            DefaultEmailAddresses{
              From
              To
              Cc
              Bcc
            }
            IsResendable
            IsOnDoNotSendList
            IsTrackingPixel
            Description
            CreatedBy
            LastUpdateBy
            CreatedDateTime
            LastUpdateDateTime
            IsActive
            Body
          }
        }`;
    await store.dispatch('templates/getTemplates', {
      query: query
    });
  },
  async getTemplate(templateId, versionId, isRefresh) {
    if (!store.state.templates.currentTemplate || (versionId !== store.state.templates.currentTemplate.VersionId && !store.state.templates.isLoading) ||
    (isRefresh === true && !store.state.templates.isLoading)) {
      let query = `query {getTemplateVersionById(Id: "${templateId}", VersionId: "${versionId}") {
                  Id
                  VersionId
                  ApplicationId
                  CreatedBy
                  CreatedDateTime
                  LastUpdateBy
                  LastUpdateDateTime
                  Name
                  Description
                  Subject
                  IsOnDoNotSendList
                  IsTrackingPixel
                  IsResendable
                  IsActive
                  DefaultEmailAddresses {
                    From
                    To
                    Cc
                    Bcc
                  }
                  Body
                  Stages {
                    Stage
                    UserId
                    PromotedDateTime
                  }
                }
              }`;
      await store.dispatch('templates/getTemplate', {
        query: query
      });
    }
  },
  async getTemplateVersions(templateId) {
    await store.dispatch('templates/getAllTemplateVersions', {
      query: `query {listAllTemplateVersionsById(Id: "${templateId}") {
          Id
          VersionId
          Name
          LastUpdateBy
          LastUpdateDateTime
          Stages {
            Stage
            UserId
            PromotedDateTime
          }
          FriendlyVersionName
        }
      }`
    });
  },
  async getUser(commonId) {
    if ((store.state.user.user && store.state.user.user.CommonId !== commonId) || !store.state.user.user) {
      let query = `query {getTeamMemberByCommonId(CommonId: "${commonId}") {
                FirstName
                LastName
                CommonId
                EmailAddress
                SubTeam
              }}`;
      await store.dispatch('user/getUserByCommonId', {
        query: query
      });
    }
    return store.state.user.user;
  },
  async deleteTemplate(templateId) {
    let query = `mutation { deleteTemplate (id: "${templateId}") {
      Name
    }}`;
    await store.dispatch('templates/deleteTemplate', { query: query });
  },
  async createTemplate(template) {
    let query = `
    mutation createTemplate($input: TemplateInput!) {
      createTemplate (input: $input) {
        Id
        VersionId
        ApplicationId
        Name
        Description
        IsOnDoNotSendList
        Subject
        DefaultEmailAddresses{
          From
          To
          Cc
          Bcc
        }
        IsTrackingPixel
        IsActive
        CreatedBy
        CreatedDateTime
        LastUpdateBy
        LastUpdateDateTime
        Body
      }
    }`;

    let variables = {
      input: {
        ApplicationId: template.appId,
        UserId: template.userId,
        Name: template.name,
        Description: template.description,
        IsOnDoNotSendList: template.isCheckDoNotSendList,
        Subject: template.subject,
        IsTrackingPixel: template.isTrackingPixel,
        DefaultEmailAddresses: {
          From: template.from,
          To: template.defaultEmailAddresses.To,
          Cc: template.defaultEmailAddresses.Cc,
          Bcc: template.defaultEmailAddresses.Bcc,
        },
        Body: template.body
      }
    };

    await store.dispatch('templates/createTemplate', { query, variables });
  },
  async promoteTemplate(templateId, versionId, userId, userTeam) {
    let query =
    `mutation {promoteTemplate(id: "${templateId}", versionId: "${versionId}", userId: "${userId}", teamName: "${userTeam}") }`;
    return store.dispatch('templates/promoteTemplate', {
      query: query
    });
  },
  async updateTemplate(template, userId) {
    let query = `
    mutation updateTemplate($id: ID!, $input: TemplateInput!) {
      updateTemplate (id: $id, input: $input) {
        Id
        VersionId
        ApplicationId
        Name
        Description
        IsOnDoNotSendList
        IsResendable
        Subject
        DefaultEmailAddresses{
            From
            To
            Cc
            Bcc
        }
        IsTrackingPixel
        IsActive
        CreatedBy
        CreatedDateTime
        LastUpdateBy
        LastUpdateDateTime
        Body
        Stages {
          Stage
          UserId
          PromotedDateTime
        }
      }
    }`;

    let variables = {
      id: template.Id,
      input: {
        Id: template.Id,
        VersionId: template.VersionId,
        ApplicationId: template.ApplicationId,
        UserId: userId,
        Name: template.Name,
        Description: template.Description,
        IsOnDoNotSendList: template.IsOnDoNotSendList,
        Subject: template.Subject,
        IsResendable: template.IsResendable,
        IsTrackingPixel: template.IsTrackingPixel,
        DefaultEmailAddresses: {
          From: template.DefaultEmailAddresses.From,
          To: template.DefaultEmailAddresses.To,
          Cc: template.DefaultEmailAddresses.Cc,
          Bcc: template.DefaultEmailAddresses.Bcc,
        },
        Body: template.Body
      }
    };

    await store.dispatch('templates/updateTemplate', { query, variables });
  }
};
