import _ from 'lodash';
import { mapGetters } from 'vuex';
import graphUtils from '../../services/graphQLUtilities/graphQLUtilities';
import bffApplicationServices from '../../services/bff/bffApplicationServices';
import store from '@/store/store';

export default {
  data() {
    return {
      search: '',
      headers: [
        {
          text: 'Name',
          align: 'left',
          sortable: true,
          value: 'LastName',
          width: '20%'
        },
        { text: 'CommonId', value: 'CommonId', width: '7%' },
        { text: 'Email Address', value: 'EmailAddress', width: '20%' },
        { text: 'Team', value: 'SubTeam', width: '15%' },
        { text: 'Roles', value: 'Roles', width: '25%', sortable: false },
        { text: '', width: '3%', value: 'blank' }
      ],
      appUsers: [],
      currentRoles: [
        { Name: 'Admin', Id: 1 },
        { Name: 'User', Id: 2 },
        { Name: 'Read-Only', Id: 3 },
        { Name: 'View History', Id: 4 }
      ],
      application: {},
      isLoading: true,
      showDeletePermissionDialogBox: false,
      selectedUser: null,
      currentUsers: [],
      searchedTeamMembers: [],
      isProcessing: false,
      autoCompleteUsers: [],
      autoCompleteSelect: '',
      autoCompleteSearch: '',
      autoCompleteLoading: false
    };
  },
  watch: {
    $route: { handler: 'loadUsers', immediate: true },
    autoCompleteSearch: async function(val) {
      if (val && val.length > 2) {
        this.autoCompleteLoading = true;
        await this.listTeamMembersByName(val);
        this.autoCompleteLoading = false;
      }
    }
  },
  methods: {
    async loadUsers() {
      this.isLoading = true;
      this.appUsers = [];
      await this.loadCurrentApplication();
      await store.dispatch('user/getApplicationPermissions', this.application.id);
      let appPermissions = this.$store.state.user.applicationPermissions;
      for (let i = 0; i < appPermissions.length; i++) {
        let userInfo = await graphUtils.getUser(appPermissions[i].userId);
        if (userInfo) {
          userInfo.Roles = [];
          userInfo.Roles.push(appPermissions[i].role);
          if (_.includes(appPermissions[i].roles, 4)) {
            userInfo.Roles.push(4);
          }
          userInfo.Roles = this.setRoles(userInfo);
          this.appUsers.push(userInfo);
        }
        this.currentUsers = _.uniqBy(this.appUsers, 'CommonId');
      }
      this.isLoading = false;
    },
    async loadCurrentApplication() {
      await store.dispatch('applications/getApplication', { userId: this.user.commonid, applicationId: this.$route.params.id });
      this.application = this.$store.state.applications.currentApplication;
    },
    setChipColor(role) {
      switch (role.Name) {
        case 'User':
          return 'primary';
        case 'Admin':
          return 'success';
        case 'Read-Only':
          return 'info';
        default:
          return 'warning';
      }
    },
    clearSelections() {
      this.selectedRole = null;
      this.selectedUser = null;
    },
    setRoles(user) {
      let textRoles = [];
      user.Roles.forEach((role) => {
        switch (role) {
          case 1:
            textRoles.push({ Name: 'Admin', Id: 1 });
            break;
          case 2:
            textRoles.push({ Name: 'User', Id: 2 });
            break;
          case 3:
            textRoles.push({ Name: 'Read-Only', Id: 3 });
            break;
          case 4:
            textRoles.push({ Name: 'View History', Id: 4 });
            break;
        }
      });
      return textRoles;
    },
    async listTeamMembersByName(teamMemberName) {
      this.autoCompleteUsers = [];
      await this.$store.dispatch('user/listTeamMembersByName', {
        query: `query {
          listTeamMembersByName(TeamMemberName: "${teamMemberName}") {
            FirstName
            LastName
            CommonId
            EmailAddress
            SubTeam
            BadgePhotoUrl
          }
        }`
      });
      this.searchedTeamMembers = this.$store.state.user.users;
      for (let i = 0; i < this.searchedTeamMembers.length; i++) {
        let autoCompleteUser = {
          text: this.searchedTeamMembers[i].FirstName + ' ' + this.searchedTeamMembers[i].LastName,
          value: this.searchedTeamMembers[i].CommonId,
          badgePhotoUrl: this.searchedTeamMembers[i].BadgePhotoUrl
        };
        this.autoCompleteUsers.push(autoCompleteUser);
      }
    },
    async addUserPermission() {
      const response = await bffApplicationServices.createUserPermission({
        appId: this.application.id,
        userId: this.autoCompleteSelect
      });

      if (!response) {
        this.$snotify.error('Failed to add user permission');
        return;
      }

      const searchedTeamMember = this.searchedTeamMembers.find((teamMember) => teamMember.CommonId === this.autoCompleteSelect);

      searchedTeamMember.Roles = [{ Name: 'Admin', Id: 1 }, { Name: 'View History', Id: 4 }];

      const searchedTMIndex = this.currentUsers.findIndex((user) => user.CommonId === searchedTeamMember.CommonId);
      if (searchedTMIndex !== -1) {
        this.$set(this.currentUsers, searchedTMIndex, searchedTeamMember);
      } else {
        this.currentUsers.push(searchedTeamMember);
      }

      this.$snotify.success('Successfully added user permission');
    },
    openDeletePermissionDialogBox(user) {
      this.showDeletePermissionDialogBox = true;
      this.selectedUser = user;
    },
    async deleteUserPermission() {
      this.showDeletePermissionDialogBox = false;

      const response = await bffApplicationServices.deleteUserPermission({
        appId: this.application.id,
        userId: this.selectedUser.CommonId
      });

      if (!response) {
        this.$snotify.error('Failed to delete user permission');
        return;
      }

      const searchedTMIndex = this.currentUsers.findIndex((user) => user.CommonId === this.selectedUser.CommonId);
      this.currentUsers.splice(searchedTMIndex, 1);

      this.$snotify.success('Successfully deleted user permission');
    }
  },
  computed: {
    ...mapGetters({
      user: ['auth/user']
    })
  }
};
