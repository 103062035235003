import bffApplicationServices from '@/services/bff/bffApplicationServices';

const state = {
  items: [],
  currentApplication: {},
  isApplicationLoading: false
};
const mutations = {
  setApplication(statePassThru, data) {
    if (!data) {
      statePassThru.currentApplication = {};
    } else {
      statePassThru.currentApplication = data;
    }
  },
  setApplications(statePassThru, data) {
    if (!data.data) {
      statePassThru.items = [];
    } else {
      statePassThru.items = data.data.data;
    }
  },
  setIsApplicationLoading(statePassThru, data) {
    statePassThru.isApplicationLoading = data;
  }
};
const actions = {
  getApplication({ commit }, data) {
    commit('setIsApplicationLoading', true);
    return bffApplicationServices.getApplicationAndUserPermissions(data).then(
      (res) => {
        commit('setApplication', res.data.data);
        commit('setIsApplicationLoading', false);
      }
    );
  },
  getApplications({ commit }, data) {
    return bffApplicationServices.getApplications(data).then(res => commit('setApplications', res));
  }
};

const getters = {
  getCurrentApplication: state => id => {
    return state.items.find(application => application.Id === id);
  }
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
