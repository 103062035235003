import store from '@/store/store';

export default {
  checkRole(roleToCheck) {
    let roleId;
    switch (roleToCheck) {
      case 'admin':
        roleId = 1;
        break;
      case 'user':
        roleId = 2;
        break;
      case 'readOnly':
        roleId = 3;
        break;
      case 'viewHistory':
        roleId = 4;
        break;
    }
    const roles = store.state.applications.currentApplication.userPermissions;
    if (!roles || !roles.length) {
      return false;
    }
    const hasRole = roles.filter((role) => role['role'] === roleId);
    if (hasRole.length > 0) {
      return true;
    }
    return false;
  }
};
