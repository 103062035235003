import appAdmin from '@/components/appAdmin/appAdmin.vue';
import emailHistory from '@/components/emailHistory/emailHistory.vue';
import graphUtils from '@/services/graphQLUtilities/graphQLUtilities';
import roleServices from '@/services/roleServices/roleServices';
import { mapState } from 'vuex';
import store from '@/store/store';

export default {
  props: { id: String },
  components: { appAdmin, emailHistory },
  data() {
    return {
      application: {},
      applicationName: null,
      applicationId: null,
      templates: [],
      loading: false,
      search: '',
      itemsPerPage: 9,
      rowsPerPageItems: [3, 6, 9, 12, -1],
      hideHistory: true,
      showAdmin: false,
      emailHistory: [],
      readOnly: true
    };
  },
  watch: {
    $route: { handler: 'fetchTemplates', immediate: true }
  },
  computed: {
    ...mapState('applications', ['currentApplication']),
    ...mapState('templates', ['items']),
    ...mapState('auth', ['user'])
  },
  async mounted() {
    if (!this.loading) {
      await this.fetchTemplates();
    }
  },
  methods: {
    async fetchTemplates() {
      this.loading = true;
      this.applicationName = null;
      this.applicationId = this.$route.params.id;
      await store.dispatch('applications/getApplication', { userId: this.user.commonid, applicationId: this.applicationId });
      if (
        !this.items ||
        this.items.length < 1 ||
        this.items[0].ApplicationId !== this.currentApplication.Id
      ) {
        await graphUtils.getTemplatesByApplicationId(this.applicationId);
      }
      this.templates = this.items;
      this.applicationName = this.currentApplication.name;
      this.showAdmin = roleServices.checkRole('admin');
      this.readOnly = roleServices.checkRole('readOnly');
      this.loading = false;
    }
  }
};
