import axios from 'axios';

let options = {
  baseURL: process.env.VUE_APP_BFF_URL,
  headers: {
    'Content-Type': 'application/json'
  }
};

const getData = async (url, params = null) => {
  try {
    const response = await axios.get(url, { ...options, params });
    return response;
  } catch (error) {
    return null;
  }
};

const postData = async (url, data) => {
  try {
    const response = await axios.post(url, data, options);
    return response;
  } catch (error) {
    return null;
  }
};

const deleteData = async (url) => {
  try {
    const response = await axios.delete(url, options);
    return response;
  } catch (error) {
    return null;
  }
};

export default {
  async getApplications(commonId) {
    return getData('api/v1/applications', { userId: commonId });
  },
  async getApplicationAndUserPermissions(data) {
    const url = `api/v1/applications/${data.applicationId}/users/${data.userId}/permissions`;
    return getData(url);
  },
  async getApplicationPermissions(applicationId) {
    const url = `api/v1/applications/${applicationId}/permissions`;
    return getData(url);
  },
  async createUserPermission({ appId, userId }) {
    const url = `api/v1/applications/${appId}/users/${userId}/permissions`;
    return postData(url);
  },
  async deleteUserPermission({ appId, userId }) {
    const url = `api/v1/applications/${appId}/users/${userId}/permissions`;
    return deleteData(url);
  },
};
